<template>
  <div>
    <div class="d-flex justify-flex-start">
      <div class="px-24 pt-16 pb-32 bg-white mr-16 bdrs-4" style="width: 350px">
        <ul class="d-flex justify-space-between fz-14">
          <li style="color:#3C4E64">通用账户</li>
          <li
            class="account-zd"
            @click="
              useRouterPush('/financial/spending-bills', {
                type: 'GENERIC'
              })
            "
            v-if="$store.getters.roles.includes('SpendingBills')"
          >
            消费账单
          </li>
        </ul>
        <div v-for="(item, index) in GENERIC_list" :key="index">
          <div
            v-if="item.account_type === 'GENERIC'"
            class="px-24 py-16 mt-24 account-cards"
          >
            <p class="fz-16 fw-600">
              {{ item.account_type_desc }}
            </p>
            <p class="mt-12 fz-14" style="line-height:16px;">
              {{ item.card_no }}
            </p>
            <p class="mt-14 fz-22 fw-600" style="line-height:34px;">
              <span class="fz-14">￥</span>{{ toConversion(item.balance) }}
            </p>

            <!-- <p class="mt-24 fz-12" style="opacity: 0.7;">余额</p> -->
            <div class="mt-26">
              <ul class="d-flex justify-end align-flex-end fz-14 ">
                <!-- <li class="fz-12">{{ toConversion(item.balance) }}元</li> -->
                <li
                  style="cursor:pointer;margin-top:-4px;font-size:14px;font-weight:500;z-index:30;border-bottom:1px solid #f2e3c6;line-height:16px;"
                  @click="handleTopUp"
                >
                  充值
                </li>
              </ul>
            </div>
            <div class="account-card">
              <img src="@/assets/image/card.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="py-16 pb-32 bg-white bdrs-4"
        style="overflow:hidden;width: 100%;"
      >
        <div class="px-24 d-flex justify-space-between">
          <p style="color:#3C4E64" class="fz-14">消费卡</p>
          <div style="color:#808AA4" class="d-flex justify-space-between fz-14">
            <p
              class="mr-16 account-zd"
              @click="
                useRouterPush('/financial/spending-bills', {
                  type: 'CONSUMER'
                })
              "
              v-if="$store.getters.roles.includes('SpendingBills')"
            >
              明细
            </p>
            <p
              class="account-zd"
              @click="useRouterPush('/customer/enterprise-detail')"
            >
              更多
            </p>
          </div>
        </div>
        <div>
          <Swiper
            class="mt-24"
            :list="swiperList"
            :slidesPerView="slidesPerView"
          />
        </div>
      </div>
      <a-modal
        :width="488"
        title="钱包充值"
        :footer="null"
        v-model:visible="visible"
        @cancel="visible = false"
      >
        <div class="mx-24 my-20" v-if="topUpNext">
          <p style="color:#FF9800" class="fz-12">
            注：请输入您需要充值的金额，充值金额不得低于{{
              minRecharge
            }}元。输入后点击下一步，我们将生成您本次充值的收款专用编号
          </p>
          <div class="d-flex align-center mt-24">
            <span style="width:100px; text-align:right; padding-right:16px;"
              >充值金额</span
            >
            <a-input
              type="number"
              suffix="元"
              :class="{ borderColor: !!topUpValueMsg }"
              v-model:value.number="topUpValue"
              :placeholder="`输入金额不得低于${minRecharge}元`"
              @change="topUpValueChange"
            ></a-input>
          </div>
          <p v-if="topUpValueMsg" style="color:#e72832;padding:5px 0 0 80px">
            {{ topUpValueMsg }}
          </p>
          <div class="d-flex align-center mt-24">
            <span style="width:100px; text-align:right; padding-right:16px;"
              >用途</span
            >
            <a-select v-model:value="usage" style="width: 100%" ref="select">
              <a-select-option
                v-for="item in usageEnums"
                :key="item"
                :value="item.label"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </div>
          <div class="text-center">
            <a-button type="primary" class="mt-40" @click="handleNext"
              >下一步</a-button
            >
          </div>
        </div>
        <div class="mx-24 mt-20" v-else>
          <p style="color:#FF9800" class="fz-12">
            温馨提示：
          </p>
          <p class="fz-12">
            1、请务必使用企业<span style="color:red;">对公账户</span
            >进行转账，以免无法开具增值税专用发票<br />
            2、请严格<span style="color:red;">按照收款编号和充值金额转账</span
            >，以免信息不一致导致充值失败<br />
            3、如操作无误款项到账后，将自动进入您的钱包
          </p>
          <div style="background: #f8f9fb;" class="mt-24 pa-16">
            <ul>
              <li>
                <span
                  class="w100 text-right mb-24 mr-8"
                  style="display:inline-block;color:#808080"
                  >收款名称:</span
                >
                <span>{{ topUpModalValue.payee_name || "--" }}</span>
              </li>
              <li>
                <span
                  class="w100 text-right mb-24 mr-8"
                  style="display:inline-block;color:#808080"
                  >收款银行:</span
                >
                <span>浙江网商银行</span>
              </li>
              <li>
                <span
                  class="w100 text-right mb-24 mr-8"
                  style="display:inline-block;color:#808080"
                  >收款专用编号:</span
                >
                <span>{{ topUpModalValue.payee_card_no || "--" }}</span>
                <span
                  class="ml-16 text_primary"
                  style="cursor: pointer;"
                  @click="handleCopy(topUpModalValue.payee_card_no)"
                  >复制编号</span
                >
              </li>
              <li>
                <span
                  class="w100 text-right mr-8"
                  style="display:inline-block;color:#808080"
                  >充值金额:</span
                >
                <span>{{ topUpModalValue.amount || "--" }}</span>
              </li>
            </ul>
          </div>
          <div class="text-center mt-40">
            <a-button type="primary" @click="() => (visible = false)"
              >确认</a-button
            >
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import { toConversion } from "@/utils/hooks/conversion";
import useRouterPush from "@/hooks/useRouterPush";
import { copyToClip } from "@/utils/tools";
import { message } from "ant-design-vue";
import { useAccountRechargeStartApi } from "@/apis/land";
import { useDictEnumApi } from "@/apis/enums";

import Swiper from "./Swiper";

export default defineComponent({
  components: {
    Swiper
  },
  props: {
    list: Array,
    slidesPerView: Number,
    minRecharge: {
      default: 500
    }
  },
  setup(props) {
    const visible = ref(false);
    const topUpValueMsg = ref();
    const topUpValue = ref();
    const usage = ref(undefined);
    const usageEnums = ref([]);
    const topUpNext = ref(true);
    const swiperList = ref([]);
    const GENERIC_list = ref([]);
    const topUpModalValue = ref();
    const accountRechargeStart = useAccountRechargeStartApi();
    const dictEnumApi = useDictEnumApi();

    watch(
      () => props.list,
      newV => {
        GENERIC_list.value = JSON.parse(JSON.stringify(newV));
        /* GENERIC: 通用
         *  ETC: ETC   "#F3535D"
         *  GAS: 油
         *  INSURANCE: 保险服务
         *  OCR: OCR  "#5A57FA ", //#5A57FA
         *  SIGN: 电子签约   "#036BE7"
         *  SMS: 短信   "#84BF07",
         *  DATA_CHECK: 数据自检   "#4A4A4A"
         *  GPS: 轨迹
         *  VERIFY: 认证    #FF9E2B",
         * */
        for (let i = 0; i < newV.length; i++) {
          if (newV[i].account_service === "ETC") {
            newV[i].color = "#0D7CF3";
          } else if (newV[i].account_service === "GPS") {
            newV[i].color = "rgba(13,124,243,0.80)";
          } else if (newV[i].account_service === "DATA_CHECK") {
            newV[i].color = "rgba(13,124,243,0.60)";
          } else if (newV[i].account_service === "GENERIC") {
            newV.splice(newV.indexOf(newV[i]), 1);
            i -= 1;
          } else {
            newV[i].color = "#0D7CF3";
          }
        }
        swiperList.value = newV;
      }
    );

    // 获取「用途」枚举项
    (async () => {
      let res = await dictEnumApi({ code: "company_recharge_service" });
      usageEnums.value = JSON.parse(res.value).map(item => {
        return { label: item.label, value: item.label };
      });
      usage.value = usageEnums.value[0].value;
    })();

    //充值金额change
    const topUpValueChange = () => {
      if (!topUpValue.value) {
        topUpValueMsg.value = "请输入充值金额";
      }

      //验证金额大小
      topUpValueMsg.value =
        topUpValue.value >= props.minRecharge
          ? topUpValue.value > 10000000000
            ? "金额不能超过10亿"
            : ""
          : `充值金额不得低于${props.minRecharge}`;
    };
    // 下一步
    const handleNext = async () => {
      //金额验证不通过return
      topUpValueChange();
      if (topUpValueMsg.value) return;
      if (topUpValue.value) topUpNext.value = false;
      topUpModalValue.value = await accountRechargeStart({
        amount: +topUpValue.value,
        ext_json: { usage: usage.value }
      });
    };

    // 复制编号
    const handleCopy = value => {
      copyToClip(value);
      message.success("复制成功");
    };

    // 充值
    const handleTopUp = () => {
      console.log("12");
      visible.value = true;
      topUpNext.value = true;
      topUpValue.value = "";
    };

    return {
      visible,
      topUpNext,
      topUpValue,
      usage,
      usageEnums,
      topUpValueMsg,
      GENERIC_list,
      swiperList,
      handleNext,
      handleCopy,
      handleTopUp,
      toConversion,
      topUpModalValue,
      topUpValueChange,
      useRouterPush
    };
  }
});
</script>
<style lang="less" scoped>
.account-zd {
  color: #808aa4;
  cursor: pointer;
}
.account-zd:hover {
  color: #1684fb;
}
.account-cards {
  position: relative;
  height: 175px;
  width: 302px;
  background: #fc974d;
  border-radius: 10px;
  color: #fff;
}
.account-card {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 10;
}
.borderColor {
  border-color: #e72832;
  box-shadow: 0 0 0 2px #ff4d4f33;
}
</style>
