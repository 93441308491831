export const serveList = [
  {
    id: "ETC",
    name: "ETC服务",
    tooltip: "提供ETC开票服务，帮助企业便捷、合规的获取ETC发票",
    text: "消费"
  },
  {
    id: "GPS",
    name: "车辆轨迹",
    tooltip: "提供车辆轨迹获取服务，帮助企业留存真实运输数据",
    text: "消费"
  },
  {
    id: "DATA_CHECK",
    name: "数据自检",
    tooltip: "交通、税务要求的相关运单数据真实性、完整性校验服务",
    text: "消费"
  },

  {
    id: "GAS",
    name: "油气",
    tooltip: "提供油气服务，帮助企业获取油品进项发票，降低运营成本",
    text: "划拨"
  },
  {
    id: "INSURANCE",
    name: "保险",
    tooltip: "提供物流保险服务，支持多种角色便捷投保，降低事故损失",
    text: "保费"
  }
];
