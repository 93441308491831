<template>
  <div
    id="mainColumnar"
    style="width: 100%; min-width:542px;height: 300px;background:#fff;border-radius:4px;"
  ></div>
</template>
<script>
import { defineComponent, watch, onMounted } from "vue";
import * as echarts from "echarts";

export default defineComponent({
  props: {
    color: {
      type: String,
      default: ""
    },
    serviceXaxis: Array,
    serviceSeries: Array
  },
  setup(props) {
    watch(props, () => {
      refreshChart();
    });
    const refreshChart = () => {
      const main = document.getElementById("mainColumnar");
      const myChart = echarts.init(main);
      myChart.setOption({
        tooltip: {
          show: true
        },
        xAxis: {
          type: "category",
          data: props.serviceXaxis,
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true
          }
        },
        yAxis: {
          type: "value",
          show: false
        },
        grid: {
          left: 60,
          right: 60,
          top: 30,
          bottom: 40
        },
        series: [
          {
            data: props.serviceSeries,
            type: "bar",
            barWidth: 32, //柱图宽度
            itemStyle: {
              barBorderRadius: [27, 27, 27, 27],
              bottom: "40px",
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#F9A465"
                },
                {
                  offset: 1,
                  color: "#FFD482"
                }
              ])
            },
            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                color: "#808080",
                fontSize: 12
              }
            }
          }
        ]
      });
    };
    onMounted(() => {
      setTimeout(() => {
        refreshChart();
      }, 100);
    });
    return {};
  }
});
</script>
