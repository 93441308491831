<template>
  <div
    id="mainRing"
    style="width: 100%; height: 307px;background:#fff;border-radius:4px;"
  ></div>
</template>
<script>
import { defineComponent, watch, onMounted, toRaw } from "vue";
import * as echarts from "echarts";

export default defineComponent({
  props: {
    serviceXaxis: Array,
    serviceSeries: Array
  },
  setup(props) {
    watch(props, () => {
      refreshChart();
    });

    const refreshChart = () => {
      const main = document.getElementById("mainRing");

      const myChart = echarts.init(main);

      const seriesData = toRaw(props.serviceSeries).map(item => {
        switch (item.name) {
          case "数据自检":
            item.itemStyle = {
              color: "#08A656"
            };
            break;
          case "ETC":
            item.itemStyle = {
              color: "#0D7CF3"
            };
            break;
          case "保险":
            item.itemStyle = {
              color: "#73B5FD"
            };
            break;
          case "轨迹":
            item.itemStyle = {
              color: "#FF8700"
            };
            break;
          case "油":
            item.itemStyle = {
              color: "#6BCA9A"
            };
            break;
        }
        return item;
      });

      myChart.setOption({
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          left: 40,
          top: 80,
          data: props.serviceXaxis
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            center: "50%",
            avoidLabelOverlap: true,
            data: seriesData,
            label: {
              normal: {
                show: true,
                position: "outer",
                formatter: "{per|{d}%}\n\n",
                borderWidth: 1,
                borderRadius: 4,
                padding: [90, -50],
                rich: {
                  per: {
                    color: "#fff",
                    backgroundColor: "#4C5058",
                    padding: [3, 4],
                    borderRadius: 4
                  }
                }
              }
            },
            //牵引线条颜色
            labelLine: {
              normal: {
                show: true,
                length: 30,
                length2: 50
              }
            }
          }
        ]
      });
    };
    onMounted(refreshChart);
    return {};
  }
});
</script>
