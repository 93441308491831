<template>
  <div class="ma-24" style="overflow:hidden">
    <!-- 企业账户 -->
    <div style="min-width:1134px;">
      <div v-if="isLandPage" class="mb-24">
        <span class="fz-16 fw-600">{{ $store.state.user.companyName }}</span>
        <a-button class="fz-12" type="link" @click="handleToLogin"
          >点击前往增值服务平台>></a-button
        >
      </div>
      <!-- 余额列表 -->
      <TotalList :list="totalList" />
      <!-- 通用账户-消费卡列表 -->
      <CardList
        class="mt-16"
        :list="cardList"
        :minRecharge="minRecharge"
        :slidesPerView="slidesPerView"
      />

      <!-- 服务概况 -->
      <div class="echart-card mt-16">
        <div class="top">
          <p class="title">服务概况</p>
          <div class="btn" @click="useRouterPush('/service-management')">
            管理
          </div>
        </div>

        <!-- 数据表 -->
        <div class="chart-box">
          <Echart
            v-for="item in serveList"
            :key="item.id"
            class="echarts"
            :id="item.id"
            :title="item.name"
            :tooltip="item.tooltip"
            :text="item.text"
            :infoArr="info_list[item.id]"
          ></Echart>
        </div>
      </div>

      <!-- 消费量消费金额图表 -->
      <EchartsList
        :dataArr="monthDataArr"
        :monthSum="monthSum"
        :timeArray="monthTimeArr"
        :dataArrMonth="dataArrMonth"
        :timeArrMonth="timeArrMonth"
        :serviceXaxis="serviceXaxis"
        :serviceSeries="serviceSeries"
        :serviceSeriePercent="serviceSeriePercent"
        :serviceXaxisPercent="serviceXaxisPercent"
      />
    </div>

    <!-- 合同弹窗 -->
    <div
      v-if="visible"
      style="position:fixed;left:0;top:0;width:100%;height:100%;background:rgba(0,0,0,0.2);z-index: 30;"
    >
      <div
        class="bg-white"
        style="min-width:691px;width:58%;border-radius:4px;position:absolute;left:50%;top:5%;bottom:5%;transform: translateX(-50%);color:#4D4D4D;"
      >
        <div
          class="d-flex justify-space-between align-center pl-24 pr-16"
          style="height:52px;border-bottom:1px solid #F5F5F5;"
        >
          <div class="">合同</div>
          <div
            style="height:18px;width:18px;text-align:center;line-height:16px;background:#F5F5F5;border-radius:50%;pointer;color:#C1C1C1;cursor:pointer;"
            @click="visible = false"
          >
            ×
          </div>
        </div>
        <div
          class="ma-48 mt-40"
          style="border:1px solid #d4d4d4;height: calc(100% - 200px);overflow: auto;padding:16px 85px;"
          v-html="content"
        ></div>
        <div class="text-center">
          <a-button type="primary" @click="goToSign">签署合同</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { state } from "@/store/modules/user";
import TotalList from "./components/TotalList";
import CardList from "./components/CardList";
import EchartsList from "./components/EchartsList";
import Echart from "./components/Echart";
import useRouterPush from "@/hooks/useRouterPush";
import { useAccountQueryApi } from "@/apis/account.js";
import {
  useAccountAmountDetailApi,
  useCompanySubscriptionListApi
} from "@/apis/land";
import { useContractGenerateApi } from "@/apis/contract";
import { getBeforeTime, getBeforeOneMonthTime } from "@/utils/hooks/beforeTime";
import { serveList } from "./constants/index";

export default defineComponent({
  name: "business-account",
  components: {
    CardList,
    TotalList,
    EchartsList,
    Echart
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const totalList = ref({});
    const cardList = ref([]);
    const monthSum = ref(0);
    const monthSeries = ref([]);
    const monthDataArr = ref([]);
    const monthTimeArr = ref([]);
    const dataArrMonth = ref([]);
    const timeArrMonth = ref([]);
    const serviceSeries = ref([]);
    const serviceXaxis = ref([]);
    const slidesPerView = ref(1); // 根据屏幕宽度调整swiper显示区域的数值
    const serviceXaxisPercent = ref([]);
    const serviceSeriePercent = ref([]);

    // 服务概况相关数据
    const info_list = ref({
      ETC: [],
      GPS: [],
      DATA_CHECK: [],
      GAS: [],
      INSURANCE: []
    });

    const isLandPage = ~location.pathname.indexOf("land-page");

    const { dataArr, timeArray } = getBeforeTime();
    monthDataArr.value = dataArr;
    monthTimeArr.value = timeArray;

    const { dataArrMon, timeArrMon } = getBeforeOneMonthTime();
    dataArrMonth.value = dataArrMon;
    timeArrMonth.value = timeArrMon;

    const accountQuery = useAccountQueryApi();
    const accountAmountDetailApi = useAccountAmountDetailApi();
    const contractGenerate = useContractGenerateApi();
    const companySubscriptionListApi = useCompanySubscriptionListApi();

    // 登录成功后调用合同的接口查看
    let conss;
    //合同弹窗显示
    const visible = ref(false);
    let content = ref("");

    onMounted(() => {
      if (store.getters.roles.includes("ViewerContract")) {
        (async () => {
          conss = await contractGenerate({
            company_id: state.companyId
          });
          if (conss) {
            content.value = conss.content;
            // visible.value = true;
          }
        })();
      }
    });

    const goToSign = () => {
      console.log({
        companyId: conss.company_id,
        id: conss.id,
        isPreview: 1
      });
      router.push({
        path: "/contract/viewer-contract",
        query: {
          companyId: conss.company_id,
          id: conss.id,
          isPreview: 1
        }
      });
    };

    //最小充值金额
    const minRecharge = ref();
    (async () => {
      const queryData = await accountQuery({
        query_amount_flag: true,
        status: "ENABLE",
        size: 200
      });
      totalList.value = await accountAmountDetailApi();
      cardList.value = queryData.list;
      minRecharge.value = queryData.ext?.recharge_amount_min;

      // 获取服务概况
      const { start_time } = getBeforeTime(4);
      console.log(getBeforeTime(4));

      const res = await companySubscriptionListApi({
        start_time: +new Date(start_time),
        end_time: +new Date()
      });
      info_list.value.ETC = res.etc_count_resp;
      info_list.value.GPS = res.gps_count_resp;
      info_list.value.DATA_CHECK = res.data_check_count_resp;
      info_list.value.GAS = res.gas_count_resp;
      info_list.value.INSURANCE = res.insurance_count_resp;
    })();

    nextTick(() => {
      window.addEventListener("resize", async () => {
        //监听浏览器窗口大小改变
        // getQuery();
        let width = document.body.clientWidth;
        if (width < 1300) {
          slidesPerView.value = 1.5;
        } else if (width > 1300 && width < 1500) {
          slidesPerView.value = 2;
        } else if (width > 1500) {
          slidesPerView.value = 2.5;
        }
      });
    });

    // 跳转到增值服务端
    const handleToLogin = () => {
      useRouterPush();
    };

    return {
      isLandPage,
      visible,
      info_list,
      serveList,
      content,
      goToSign,
      dataArr,
      monthSum,
      cardList,
      totalList,
      timeArray,
      monthDataArr,
      monthTimeArr,
      dataArrMonth,
      timeArrMonth,
      minRecharge,
      monthSeries,
      serviceXaxis,
      serviceSeries,
      slidesPerView,
      serviceXaxisPercent,
      serviceSeriePercent,
      useRouterPush,
      handleToLogin
    };
  }
});
</script>

<style lang="less" scoped>
.echart-card {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px rgba(0, 122, 255, 0.05);
  width: 100%;
  padding: 16px 0 16px 16px;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 600;
    }
    .btn {
      color: #8494a4;
      margin-right: 24px;
      cursor: pointer;
    }
    .btn:hover {
      color: #1684fb;
    }
  }
  .chart-box {
    overflow: auto;
    width: 100%;
    display: flex;
  }
  .echarts {
    margin-right: 16px;
  }
}
</style>
