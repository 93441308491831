<template>
  <div>
    <div>
      <div class="total-list py-18 d-flex">
        <ul class="text-center d-flex" style="width: 100%">
          <li style="width:20%;">
            <p class="fz-14 mb-6" style="color:#8494A4">总余额 (元)</p>
            <p class="fz-24">
              {{ toConversion(list.total_balance, "toLocaleString") }}
            </p>
          </li>
          <li class="line"></li>
          <li style="width:20%">
            <p class="fz-14 mb-6" style="color:#8494A4">本月消费 (元)</p>
            <p class="fz-24" style="color:#1684FB">
              {{
                toConversion(list.current_month_counsume, "toLocaleString") ||
                  "--"
              }}
            </p>
          </li>
          <li class="line"></li>
          <li style="width:20%">
            <p class="fz-14 mb-6" style="color:#8494A4">本月充值 (元)</p>
            <p class="fz-24" style="color:#00BF5F">
              {{
                toConversion(list.current_month_recharge, "toLocaleString") ||
                  "--"
              }}
            </p>
          </li>
          <li class="line"></li>
          <li style="width:20%">
            <p class="fz-14 mb-6" style="color:#8494A4">总消费 (元)</p>
            <p class="fz-24">
              {{ toConversion(list.total_counsume, "toLocaleString") }}
            </p>
          </li>
          <li class="line"></li>
          <li style="width:20%">
            <p class="fz-14 mb-6" style="color:#8494A4">总充值 (元)</p>
            <p class="fz-24">
              {{ toConversion(list.total_recharge, "toLocaleString") }}
            </p>
          </li>
          <li class="line"></li>
          <li style="width:20%">
            <p class="fz-14 mb-6" style="color:#8494A4">可开票总金额 (元)</p>
            <p class="fz-24" style="color:#FF8700;">
              {{ toConversion(list.invoicable_amount, "toLocaleString") }}
            </p>
            <div
              v-if="$store.getters.roles.includes('ApplyInvoice')"
              class="link-btn"
              @click="useRouterPush('/invoice/apply-invoice')"
            >
              申请开票
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { toConversion } from "@/utils/hooks/conversion";
import useRouterPush from "@/hooks/useRouterPush";

export default defineComponent({
  props: {
    list: Object
  },
  setup(props) {
    console.log(props.list);
    return {
      toConversion,
      useRouterPush
    };
  }
});
</script>
<style lang="less" scoped>
.total-list {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px rgba(22, 132, 251, 0.05);
}
.line {
  width: 1px;
  height: 16px;
  background: #d4d4d4;
  margin-top: 28px;
}
.link-btn {
  color: #1684fb;
  margin-top: 4px;
  cursor: pointer;
}
</style>
