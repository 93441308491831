import router from "@/router";

export default function(path = "", query) {
  var _str = "";
  for (var o in query) {
    _str += o + "=" + query[o] + "&";
  }
  _str = _str.substring(0, _str.length - 1);

  // 判断是否是落地页 落地页打开新窗口
  if (~location.pathname.indexOf("land-page")) {
    const login_path =
      process.env.NODE_ENV === "production"
        ? "/api/portal/company/login"
        : process.env.VUE_APP_BASEURL + "/portal/company/login";

    window.open(
      `${login_path}?redirect_url=${encodeURIComponent(
        location.origin + path + "?" + _str
      )}&token=${getUrlParams("token")}`
    );
  } else {
    router.push({ path, query });
  }
}

// 获取url中某个参数
function getUrlParams(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let param = window.location.search.substr(1).match(reg);
  return param && param[2];
}

// 删除url中某个参数
// function removeURLParameter(url, parameter) {
//   var urlParts = url.split("?");
//   if (urlParts.length >= 2) {
//     //参数名前缀
//     var prefix = encodeURIComponent(parameter) + "=";
//     var pars = urlParts[1].split(/[&;]/g);

//     //循环查找匹配参数
//     for (var i = pars.length; i-- > 0; ) {
//       if (pars[i].lastIndexOf(prefix, 0) !== -1) {
//         //存在则删除
//         pars.splice(i, 1);
//       }
//     }

//     return urlParts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
//   }
//   return url;
// }
