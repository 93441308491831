<template>
  <div>
    <!-- 消费金额柱状图-环形图 -->
    <div class="mt-16 d-flex justify-space-between bdrs-4 ">
      <div style="width:50%;" class="mr-16 bdrs-4 bg-white">
        <div class="px-24 pt-16 bdrs-4 ">
          <p class="fz-14">近6个月消费情况（元）</p>
        </div>
        <EchartsColumnar
          :serviceXaxis="serviceXaxis"
          :serviceSeries="serviceSeries"
        />
      </div>
      <div style="width:50%" class="bg-white px-24 pt-16 fz-14 bdrs-4">
        <div class="d-flex justify-space-between">
          <div>
            月度各项服务支出金额及其占比
          </div>
          <a-month-picker
            v-model:value="monthValue"
            placeholder="Select month"
            valueFormat="YYYYMM"
          />
        </div>

        <EchartsRing
          class="mt-16"
          :serviceXaxis="serviceXaxisPercent"
          :serviceSeries="serviceSeriePercent"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watchEffect } from "vue";
import EchartsRing from "./EchartsRing";
import EchartsColumnar from "./EchartsColumnar";
import { getBeforeTime } from "@/utils/hooks/beforeTime";
import { useAccountByMonthApi, useAccountByServiceApi } from "@/apis/land";
import moment from "moment";

export default defineComponent({
  components: {
    EchartsRing,
    EchartsColumnar
  },
  props: {
    dataArr: Array,
    timeArray: Array,
    monthSum: Number,
    monthSeries: Array,
    dataArrMonth: Array,
    timeArrMonth: Array,
    serviceSeries: Array,
    serviceXaxis: Array,
    serviceXaxisPercent: Array,
    serviceSeriePercent: Array
  },
  setup(props, context) {
    const selectValue = ref(12);
    const selectValueMonth = ref(props.dataArrMonth[0]);

    //近六月数据处理
    const uesSix = () => {
      const serviceXaxis = ref([]);
      const serviceSeries = ref([]);
      const mapData = {};

      const { oneYear } = getBeforeTime();
      serviceXaxis.value = oneYear.slice(6);
      const accountByMonth = useAccountByMonthApi();
      (async () => {
        let res = await accountByMonth({
          end_ym: serviceXaxis.value[5],
          start_ym: serviceXaxis.value[0]
        });
        res.map(item => {
          mapData[item.operating_time] = item.money;
        });

        //key=》value
        serviceXaxis.value?.map(key => {
          let value = mapData[key] || 0;
          serviceSeries.value.push(value ? value / 100 : value);
        });
      })();
      return {
        serviceXaxis,
        serviceSeries
      };
    };

    //六月数据处理函数
    const sixData = uesSix();

    //消费分布
    const distribution = () => {
      const serviceXaxisPercent = ref([]);
      const serviceSeriePercent = ref([]);
      //默认时间
      const monthValue = ref();
      monthValue.value = moment()
        .subtract(1, "months")
        .format("YYYYMM");
      const accountByService = useAccountByServiceApi();

      const MonthDatePaint = async () => {
        serviceXaxisPercent.value = [];
        serviceSeriePercent.value = [];
        let res = await accountByService({
          // start_ym: 202105
          start_ym: +monthValue.value
        });
        res.map(item => {
          serviceXaxisPercent.value.push({
            name: item.service_group_desc,
            icon: "circle"
          });
          serviceSeriePercent.value.push({
            name: item.service_group_desc,
            value: item.money / 100,
            itemStyle: {
              color: "#443425"
            }
          });
        });
      };
      watchEffect(MonthDatePaint);
      return {
        serviceXaxisPercent,
        serviceSeriePercent,
        monthValue
      };
    };

    //消费分布
    const distributionData = distribution();

    // 12个月消费量下拉
    const handleChange = value => {
      context.emit("seleceValue", value);
    };

    // 各项服务月消费额下拉
    const handleMonthChange = value => {
      let num = value.slice(0, 4);
      let mon = value.slice(5, 7);
      let month = [num + mon];
      context.emit("selectService", month);
    };

    return {
      ...sixData,
      ...distributionData,
      selectValue,
      handleChange,
      selectValueMonth,
      handleMonthChange
    };
  }
});
</script>
