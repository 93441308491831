<template>
  <div>
    <swiper
      slides-per-view="auto"
      :space-between="24"
      :loop="false"
      navigation
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      class="swiper-container-vuecontent"
    >
      <swiper-slide
        style="width: 302px!important;"
        v-for="(item, index) in list"
        :key="index"
      >
        <div
          class="px-24 py-16 account-cards"
          :style="{ background: item.color }"
        >
          <!-- <p class="fz-16 fw-600">消费卡-{{ item.account_service_desc }}</p>
          <p class="mt-24 fz-24" style="line-height:28px;">
            {{ item.card_no }}
          </p> -->
          <p class="fz-16 fw-600">消费卡-{{ item.account_service_desc }}</p>
          <p class="mt-12 fz-14" style="line-height:16px;">
            {{ item.card_no }}
          </p>
          <p class="mt-14 fz-22" style="line-height:34px;">
            <span class="fz-12">￥</span>{{ toConversion(item.balance) }}元
          </p>
          <div class="mt-24">
            <ul class="d-flex justify-space-between">
              <li class="d-flex align-center">
                <p class="fz-12 mr-6" style="opacity: 0.7;">面值</p>
                <p>{{ toConversion(item.recharge_amount) }}元</p>
              </li>
              <!-- <li>
                <p class="fz-12 mb-6" style="opacity: 0.7;">余额</p>
                <p>{{ toConversion(item.balance) }}元</p>
              </li> -->
              <li class="d-flex align-center">
                <p class="fz-12 mr-6" style="opacity: 0.7;">有效期至</p>
                <p>{{ moment(+item.valid_end).format("YYYY.MM.DD") }}</p>
              </li>
            </ul>
          </div>
          <div class="account-card">
            <img src="@/assets/image/card.svg" alt="" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import SwiperCore, { Navigation, A11y } from "swiper";
import { toConversion } from "@/utils/hooks/conversion";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper.less";
import "swiper/components/navigation/navigation.less";
SwiperCore.use([Navigation, A11y]);

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    list: Array,
    slidesPerView: Number
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper);
    };
    const onSlideChange = () => {};
    return {
      moment,
      onSwiper,
      toConversion,
      onSlideChange
    };
  }
});
</script>
<style lang="less" socped>
.swiper-container-vuecontent {
  display: flex;
  padding: 0 24px;
  .swiper-button-prev:after {
    content: "prev";
    font-size: 16px;
    position: absolute;
    left: 0;
    font-family: swiper-icons;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
    width: 24px;
    height: 180px;
    text-align: center;
    line-height: 180px;
    z-index: 100;
    background: #fff;
  }
  .swiper-button-next:after {
    content: "next";
    font-size: 16px;
    position: absolute;
    right: 0;
    font-family: swiper-icons;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
    width: 24px;
    height: 180px;
    text-align: center;
    line-height: 180px;
    z-index: 100;
    background: #fff;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    top: 20px;
    right: 0px;
    width: 24px;
    height: 180px;
    color: #8494a4;
    background: #fff;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    top: 20px;
    left: 0px;
    width: 24px;
    height: 180px;
    color: #8494a4;
    background: #fff;
  }
}
.account-cards {
  position: relative;
  height: 175px;
  width: 302px;
  background: #1684fb;
  border-radius: 10px;
  color: #fff;
}
.account-card {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 10;
}
</style>
