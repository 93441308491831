<template>
  <div class="echart-content">
    <div class="d-flex justify-space-between">
      <div class="title">
        <p>{{ title }}</p>
        <div class="ml-8">
          <a-tooltip placement="bottom">
            <template #title>
              <p style="width:200px;">
                {{ tooltip }}
              </p>
            </template>
            <QuestionCircleOutlined class="fz-12" style="color:#C1C1C1" />
          </a-tooltip>
        </div>
      </div>
      <Icon
        style="cursor:pointer;"
        name="iconfuwugaikuang-tiaozhuan"
        height="20"
        width="20"
        @click="handleRouterPush"
      ></Icon>
    </div>

    <p class="mt-12" style="color:#808080;">
      本月{{ text }}： {{ series_data[3] }}元
    </p>

    <div class="chart" :id="id"></div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import * as echarts from "echarts";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { getBeforeTime } from "@/utils/hooks/beforeTime";
import useRouterPush from "@/hooks/useRouterPush";

export default {
  name: "Echart",
  components: { QuestionCircleOutlined },
  props: {
    id: { type: String, default: "" },
    title: { type: String, default: "" },
    tooltip: { type: String, default: "" },
    text: { type: String, default: "" },
    infoArr: { type: Array, default: () => [] }
  },

  setup(props) {
    const series_data = ref([]);

    watch(
      () => props.infoArr,
      val => {
        series_data.value = val.map(item => item.money / 100 ?? 0);
        refreshChart();
      }
    );
    const refreshChart = () => {
      var chartDom = document.getElementById(props.id);
      var myChart = echarts.init(chartDom);
      var option;
      const { monthArr } = getBeforeTime(4);

      let colors = ["#ff7c24"];
      switch (props.id) {
        case "ETC":
          colors = ["#ff7c24"];
          break;
        case "GPS":
          colors = ["#1684FB"];
          break;
        case "DATA_CHECK":
          colors = ["#08A656"];
          break;
        case "GAS":
          colors = ["#6BCA9A"];
          break;
        case "INSURANCE":
          colors = ["#59AAF6"];
          break;
      }
      option = {
        color: colors,
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            return params.data.toString();
          }
        },

        grid: {
          left: -8,
          right: 24,
          bottom: 3,
          height: 120,
          width: "auto",
          containLabel: true
        },
        xAxis: {
          splitLine: {
            show: false
          },
          type: "category",
          boundaryGap: false,
          data: monthArr
        },
        yAxis: {
          type: "value",
          show: false
        },
        series: [
          {
            type: "line",
            smooth: true,
            data: series_data.value
          }
        ]
      };
      option && myChart.setOption(option);
    };

    const handleRouterPush = () => {
      switch (props.id) {
        case "GAS":
          useRouterPush("/oilCard/transfer-record", {
            tab: props.id
          });
          break;
        case "INSURANCE":
          break;
        default:
          useRouterPush("/order/service-order", {
            tab: props.id
          });
          break;
      }
    };

    return { series_data, handleRouterPush, useRouterPush };
  }
};
</script>

<style lang="less" scoped>
.echart-content {
  height: 221px;
  min-width: 260px;
  flex: 1;
  background: rgba(248, 249, 251, 0.8);
  border-radius: 4px;
  margin-top: 24px;
  padding: 24px 16px;
  .chart {
    height: 125px;
    width: 100%;
    margin-top: 5px;
  }
  .title {
    display: flex;
  }
}
</style>
